import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Mix Rider</h1>
            </header>
            <div className="content">
                <p>Touchscreen DJ Controller App <br />
                for Android Devices</p>
                <div className="play-store-button">
                <a href='https://play.google.com/store/apps/details?id=com.spinims.rider&hl=en-GB&ah=2QFlzfMPPrRnisWWVXPNz_kGnhk&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                </div>
            </div>
        </div>
    </section>
)

export default Banner
