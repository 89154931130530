import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import mixxx from '../assets/images/mixxx.png'
import mixxxLogo from '../assets/images/mixxx-logo.png'
import controllerImage from '../assets/images/dj-serato-pioneer-pioneer-dj.jpg'
import mixScreenShot from '../assets/images/mix-ss.png'
import noAds from '../assets/images/noads.png'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Mix Rider - DJ Controller App"
                    meta={[
                        { name: 'description', content: 'Touchscreen DJ Controller App Supporting Mixxx and Android Devices' },
                        { name: 'keywords', content: 'dj, controller, android, app, mixxx' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${mixScreenShot})`}}>
                            <header className="major">
                                <h3>Precision Control</h3>
                                <p>Built for beat matching with digital or analog sources</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${controllerImage})`}}>
                            <header className="major">
                                <h3>Alternative to Hardware Controllers</h3>
                                <p>For compact and portable option or as a back up to your bulky DJ hardware controller </p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${mixxx})`}}>
                            <header className="major">
                                <h3>Works with <img src={mixxxLogo} alt="MIXXX Logo"/> DJ Software</h3>
                                <p>Suports remote browsing, cueing, tempo control and looping</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${noAds})`}}>
                            <header className="major">
                                <h3>Mix Rider is free</h3>
                                <p>Basic version has no cost and contains no ads or tracking</p>
                            </header>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>What is Mix Rider?</h2>
                            </header>
                            <p>Mix Rider is an Android application functioning as a MIDI remote control for professional DJ software such as Mixxx (currently only Mixxx is supported).</p>
                            <p><b>This app will not play music files directly. </b>  A USB cable connection from your Android device to a computer with the Mixxx software package installed is required to start DJing using this app.</p>
                            <p>Mix Rider works well with house, disco, trance or techno style DJing with a focus on tactile responsiveness and manual beatmatching capability.</p>
                            <ul className="actions">
                                <li><Link to="/setup" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex